import React, { Component, setState } from 'react';
import Comment from "./comments"
import app from 'gatsby-plugin-firebase-v9.0'
import { getDatabase, ref, onValue, push } from "firebase/database";
import moment from 'moment';
import ImageFloral from '../../images/floral.webp'


class FormComment extends Component {
    constructor(props) {
        super(props);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeComment = this.onChangeComment.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);
    }


    state = {
        comment: '',
        name: '',
        dateTime: moment().format("DD-MM-YYYY hh:mm"),
        isShown: true,
    }

    onChangeName(event) {
        this.setState({ name: event.target.value });
    }

    onChangeComment(event) {
        this.setState({ comment: event.target.value });
    }

    onSubmitForm(e) {
        e.preventDefault();
        const database = getDatabase(app);
        const dataRef = ref(database, 'view');
        push(dataRef, this.state);
        this.setState({
            comment: '',
            name: '',
            dateTime: moment().format("DD-MM-YYYY hh:mm"),
            isShown: true,
        })
    }

    render() {
        return (
            <>
                <img src={ImageFloral} className="z-0" />
                <form className="w-full -mt-32 z-10">
                    <div className="flex flex-wrap mb-6 mx-3 bg-orange-100 rounded-lg border border-orange-900">
                        <h2 className="px-4 py-1 text-yellow-900 border-b border-orange-900 w-full text-center">Sampaikan Salam dan Doa</h2>
                        <div className="w-full md:w-full px-3 mb-2 mt-2">
                            <input
                                className="bg-orange-50 rounded border border-orange-900 leading-normal resize-none w-full p-1 placeholder-yellow-900 focus:outline-none "
                                placeholder='Nama Kamu'
                                value={this.state.name}
                                onChange={this.onChangeName}
                                required />
                        </div>
                        <div className="w-full md:w-full px-3 mb-2 mt-2">
                            <textarea
                                className="bg-orange-50 rounded border border-orange-900 leading-normal resize-none w-full h-20 p-1 placeholder-yellow-900 focus:outline-none"
                                placeholder='Doa Kamu Untuk Tika dan Rexi'
                                value={this.state.comment}
                                onChange={this.onChangeComment}
                                required>
                            </textarea>
                        </div>
                        <div className="w-full md:w-full flex items-start px-3">

                            <div className="-mr-1">
                                <input type='submit' className="bg-orange-50 text-yellow-900 font-medium py-1 px-4 border border-orange-900 rounded-lg tracking-wide mr-1 hover:border-orange-800" value='Kirim Doa' onClick={this.onSubmitForm} onSubmit={this.onSubmitForm} />
                            </div>
                        </div>

                        <div className='w-full mt-5'></div>
                        <Comment />
                    </div>
                </form>

            </>

        );
    }
}


export default FormComment;