import React, { Component } from 'react';
import app from 'gatsby-plugin-firebase-v9.0'
import { getDatabase, ref, onValue, push } from "firebase/database";
import moment from 'moment';
import AmplopeImage from '../../images/icon-amplop.png'
import FormComment from './form-comment';


class Comment extends Component {
    state = {
        dataComment: [],
        isLoading: true,
        page_number: 1,
        per_page: 5
    };

    constructor(props) {
        super(props);
    }


    componentDidMount() {
        const database = getDatabase(app);
        const dataRef = ref(database, 'view');
        var data = {};
        onValue(dataRef, (snapshot) => {
            data = snapshot.val();
            let arr = [];
            Object.keys(data).map((obj, i) => {
                if (!data[obj].isShown) return;
                if (!data[obj].name) return;
                data[obj].id = obj;
                data[obj].order = i;
                arr.push(data[obj]);
            });
            arr.sort((a, b) => b.order - a.order);
            console.log(arr);
            this.setState({
                dataComment: arr,
                isLoading: false,
            });
        });

    }

    paginate(array) {
        return array.slice((this.state.page_number - 1) * this.state.per_page, this.state.page_number * this.state.per_page);
    }

    renderObj(data) {
        if (data == null) return;
        return this.paginate(data).map((obj, i) => {
            return (
                <div className="p-1 antialiased flex w-full border-t border-orange-900" key={obj.id}>
                    <div className='w-full flex justify-start items-start'>
                        <div className='w-8 h-8 mt-2 ml-2'>
                            <img src={AmplopeImage} />
                        </div>
                        <div className="rounded-lg px-2 pt-2 pb-2.5 w-full">
                            <div className="font-semibold text-sm leading-relaxed ">
                                {obj.name}
                            </div>
                            <div className='text-xs mt-0.5 text-gray-500 dark:text-gray-400'>
                                {obj.dateTime}
                            </div>
                            <div className="text-normal leading-snug md:leading-normal">
                                {obj.comment}
                            </div>

                        </div>
                    </div>
                </div >
            )
        });
    }



    render() {
        if (this.state.isLoading)
            return (<div className="border border-blue-300 bg-white shadow rounded-md max-w-sm w-full mx-auto">
                <div className="animate-pulse flex space-x-4">
                    <div className="rounded-full bg-slate-200 h-10 w-10"></div>
                    <div className="flex-1 space-y-6 py-1">
                        <div className="h-2 bg-slate-200 rounded"></div>
                        <div className="space-y-3">
                            <div className="grid grid-cols-3 gap-4">
                                <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                                <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                            </div>
                            <div className="h-2 bg-slate-200 rounded"></div>
                        </div>
                    </div>
                </div>
            </div>);


        return (
            <>
                <div className='w-full'>
                    {this.renderObj(this.state.dataComment)}
                </div>
                <div className='flex justify-between w-full border-t border-orange-900 p-5'>

                    <div
                        className={this.state.page_number <= 1 ? 'opacity-0' : '' + 'bg-orange-50 text-yellow-900 font-medium py-1 px-4 border border-orange-900 rounded-lg tracking-wide mr-1 hover:border-orange-800'}
                        onClick={this.state.page_number > 1 ? () => this.setState({ page_number: this.state.page_number - 1 }) : () => { }}>
                        Sebelumnya
                    </div>
                    <div
                        className='bg-orange-50 text-yellow-900 font-medium py-1 px-4 border border-orange-900 rounded-lg tracking-wide mr-1 hover:border-orange-800'
                        onClick={this.state.page_number * this.state.per_page < this.state.dataComment.length ? () => this.setState({ page_number: this.state.page_number + 1 }) : () => { }}>
                        Berikutnya
                    </div>
                </div>

            </>

        );
    }
}

export default Comment;
