"use strict";

import React, { Component } from 'react'
import ImageTemaAtas from '../images/tema-atas.png'
import ImageFloral from '../images/floral.webp'
import ImageCover from "../images/cover-1-cewek.webp"
import ImageWalimatul from '../images/walimatul-ursy-b.webp'

var countDownDate = new Date("May 15, 2022 08:00:00").getTime();
// Update the count down every 1 second


class TemaAtas extends Component {

    state = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    };

    timerFunction() {

        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);



        // If the count down is finished, write some text
        if (distance < 0) {
            clearInterval(x);
        }

        this.setState({
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds
        });
    }

    runTimer() {
        var x = setInterval(this.timerFunction.bind(this), 1000);

        return x;
    }



    render() {
        this.runTimer();
        return (
            <>
                <div className='pt-3'>
                    <img src={ImageTemaAtas} />
                    <div className='flex flex-col justify-center items-center'>
                        <img src={ImageWalimatul} width="200" />
                        <img src={ImageCover} width="400px" />
                        <div className="font-ramadhan tracking-wide text-6xl pt-10 text-gold">
                            Tika & Rexi
                        </div>
                        <div className='text-center px-5 mt-10 text-yellow-900 font-kalam text-lg'>
                            Tanpa mengurangi rasa hormat, Kami mengundang Bpk/Ibu/Saudara/i Serta kerabat sekalian untuk  menghadiri acara Pernikahan kami
                        </div>

                        <div className='grid grid-cols-4 gap-3 my-10 mx-5'>
                            <div className='bg-amber-800 px-10 py-5 rounded-2xl text-white text-center flex flex-col justify-center items-center'>
                                <div className='text-2xl'> {this.state.days}</div>
                                <div> Hari</div>
                            </div>
                            <div className='bg-amber-800 px-10 py-5 rounded-2xl text-white text-center flex flex-col justify-center items-center'>
                                <div className='text-2xl'> {this.state.hours}</div>
                                <div> Jam</div>
                            </div>
                            <div className='bg-amber-800 px-10 py-5 rounded-2xl text-white text-center flex flex-col justify-center items-center'>
                                <div className='text-2xl'> {this.state.minutes}</div>
                                <div> Menit</div>
                            </div>
                            <div className='bg-amber-800 px-10 py-5 rounded-2xl text-white text-center flex flex-col justify-center items-center'>
                                <div className='text-2xl'> {this.state.seconds}</div>
                                <div> Detik</div>
                            </div>
                        </div>

                    </div>
                    <img src={ImageFloral} />
                </div>

            </>
        )
    }
}

export default TemaAtas;