import React from 'react'

function DenahLokasi() {
    return (
        <>
            <div className='p-5 mt-10 h-96 text-center'>
                <div className="font-courgette font-bold text-2xl border-b border-orange-900">Denah Lokasi</div>
                <div className='font-kalam mb-5 border-b border-orange-900'> Jl. Andalas Gg. Sarga Indah No. 45 <br />RT. 003 RW. 003 Kel. Andalas Kec. Padang Timur Kota Padang</div>

                <div className='w-full h-52 '>

                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.2869771303463!2d100.38260741475362!3d-0.935090099317864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2d7348a9ae5cd1b1!2zMMKwNTYnMDYuMyJTIDEwMMKwMjMnMDUuMyJF!5e0!3m2!1sen!2sid!4v1651587016095!5m2!1sen!2sid" width="100%" height="250" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </>
    )
}

export default DenahLokasi;