import React from "react"
import FloralImage from "../images/floral-3.webp"
import BankNagari from "../images/logo-bank-nagari.webp"

function AmplopOnline({ className, onClick }) {
    return (
        <>
            <img src={FloralImage} />
            <div className="p-10 text-xl text-center">Amplop Online</div>
            <div className="flex flex-col justify-center items-center">

                <img src={BankNagari} width="200" />
                <div>10010210057330</div>
                <div>Sustika Hamid</div>
            </div>

        </>
    )
}

export default AmplopOnline;
