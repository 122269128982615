import React, { Component } from "react"
import Layout from "../components/layouts"
import FormComment from "../components/comment/form-comment";
import Cover from "../components/cover";
import Player from "../components/audio-player";
import AOS from 'aos';

import "aos/dist/aos.css";
import TemaAtas from "../components/tema-atas";
import TemaMainWedding from "../components/tema-wedding";
import TemaWaktu from "../components/tema-waktu";
import DenahLokasi from "../components/denah-lokasi";
import AmplopOnline from "../components/amplop";



class App extends Component {
  state = {
    hideCover: false,
    runAnimation: false
  }

  constructor(props) {
    super(props);
    if (typeof window !== `undefined`) {
      AOS.init({
        duration: 600
      });
    }
  }

  handleClick = () => {
    this.setState({ runAnimation: true });
    setTimeout(() => {
      this.setState({
        hideCover: true
      })
    }, 300);
  }

  render() {
    console.log(this.state.hideCover);
    if (!this.state.hideCover) {
      return (
        <Cover className={`w-full h-full overflow-hidden transition-all ease-in-out duration-300 bg-gray-200 items-center justify-between ${this.state.runAnimation ? '-translate-y-full' : 'translate-y-0'}`} onClick={this.handleClick} />
      )
    } else
      return (
        <>

          <div data-aos="fadeIn"
            data-aos-easing="ease-in-out"
            data-aos-duration="700">



            <div className="absolute lg:right-[200px] right-5 top-[300px] z-50">

              <Player />
            </div>


            <Layout className="bg-amber-50 rounded-[30px] mb-32 block min-h-screen">


              <TemaAtas />

              <TemaMainWedding />

              <TemaWaktu />

              <DenahLokasi />
              <AmplopOnline />

              <FormComment />

              <div className="pb-32"></div>

            </Layout>
          </div>

        </>
      );
  }
}

export default App;