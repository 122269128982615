import React from 'react';
import PropTypes from "prop-types"
import FeatureImage from './feature-image';
import Emvelope from '../images/emvelope.webp'
import EmvelopeBack from '../images/envelope-back.png'
import EmvelopeFLower from '../images/envelope-flower.png'
import { useQueryParam, NumberParam, StringParam } from "use-query-params";
import ImageCover from "../images/cover-1.webp"

function Layout({ children, number, className }) {
    const [foo, setFoo] = useQueryParam("to", StringParam);

    return (
        <div className='bg-gradient-to-b from-[#e7d18f] to-[#fcd357] min-h-screen'>
            <div className='xl:w-[40%] w-full h-screen mx-auto static'>
                <div className='absolute xl:w-[40%] w-full h-1/2 z-0 flex flex-col justify-center items-center'>
                    <img src={ImageCover} width="350" className='pt-16' />
                </div>

                <div className='absolute xl:w-[40%] w-full bottom-0 z-0'>
                    <img src={EmvelopeBack} className="w-full" />
                </div>
                <div className='absolute xl:w-[40%] w-full bottom-0 z-10'>
                    <img src={EmvelopeFLower} className="w-full" />
                </div>
                <div className="absolute xl:w-[40%] w-full z-30 h-screen overflow-scroll scrollbar-hide drop-shadow-2xl">
                    <div className='xl:mt-[70%] mt-[99%] xl:pt-10 pt-10'>
                        <div className={`w-[95%] mx-auto z-20 flex flex-col mb-32`}>
                            <div className='h-60'></div>
                            <div className={className}>
                                {children}
                            </div>

                        </div>
                    </div>
                </div>
                <div className='absolute xl:w-[40%] w-full bottom-0 z-40'>
                    <img src={Emvelope} className="w-full" />
                </div>
            </div>
        </div >
    );
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    number: PropTypes.number,
}

export default Layout;
