import React from "react"
import ImageCover from "../images/cover-1-cewek.webp"
import ImageWalimatul from '../images/walimatul-ursy.png'
import { useQueryParam, NumberParam, StringParam } from "use-query-params";


function Cover({ className, onClick }) {
    const [foo, setFoo] = useQueryParam("to", StringParam);

    return (<>
        <div className={className}>

            <div className="flex flex-col items-center justify-center h-screen bg-zinc-800">
                <div className="font-mono text-3xl pt-5 text-gold">
                    <img src={ImageWalimatul} width="200" />
                </div>
                <img src={ImageCover} width="300px" onClick={onClick} />
                <div className="font-ramadhan tracking-wide text-6xl pt-10 text-gold">
                    Tika & Rexi
                </div>
                <div className="text-center pt-10 text-white">
                    <div className="text-zinc-300">Kpd. Bpk/Ibu/Saudara/i</div>
                    <div className="text-2xl font-bold">{foo ? foo : 'Undangan yth'}</div>
                </div>

                <div onClick={onClick} className="px-10 py-3 mt-5 mb-32 text-white  outline outline-1 outline-white rounded-xl">Buka Undangan</div>
            </div>
        </div>
    </>)
}

export default Cover;
