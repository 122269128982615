import React, { useEffect, useState } from "react";
import audio from '../audio/song.mp3';
import useSound from 'use-sound';


const Player = ({ className }) => {
    const [play, { stop }] = useSound(audio);

    const [isPlaying, setIsPlaying] = useState(true);


    useEffect(() => {
        play();
        return () => {
            play();
        };
    }, [play]);

    return (
        <div className={className}>
            {isPlaying ?
                <button className="flex items-center justify-center bg-orange-500 rounded-full h-10 w-10"
                    onClick={() => {
                        if (isPlaying) {
                            setIsPlaying(false);
                            stop();
                        } else {
                            console.log("PLAYING");
                            setIsPlaying(true);
                            play();
                        }
                    }}>
                    <svg className="h-12 w-12 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                </button> :
                <button className="flex items-center justify-center bg-orange-500 rounded-full h-10 w-10"
                    onClick={() => {
                        if (isPlaying) {
                            setIsPlaying(false);
                            stop();
                        } else {
                            console.log("PLAYING");
                            setIsPlaying(true);
                            play();
                        }
                    }}>
                    <svg className="h-12 w-12 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.752 11.108l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"></path>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                </button>
            }

        </div>
    );
};

export default Player;