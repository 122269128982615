import React from 'react'
import LampImage from '../images/lamp.webp'
import DekorasiImage from '../images/dekorasi.png'

function TemaWaktu() {
    return (
        <>
            <div className='w-full -mt-36'>
                <img src={LampImage} />
                <div className='-mt-52 font-great-vibes text-6xl pl-14 text-yellow-900'>Save
                    <br />the Date</div>
                <img src={DekorasiImage} width="250" className='-mb-32 ml-10' />
                <div className='font-kalam font-bold px-10 pt-32'>
                    <div className='flex flex-col justify-end items-end text-right pb-10'>

                        <div className='font-base text-3xl'>Akad Nikah</div>
                        <div className='flex'>
                            <div className='text-5xl'>15</div>
                            <div>
                                <div>Minggu</div>
                                <div>Mei 2022</div>
                            </div>
                        </div>
                        <div>Pukul: 08:10 Am - 10:00 Am</div>
                    </div>
                    <div className='text-left pt-5'>
                        <div className='font-base text-3xl'>Resepsi</div>
                        <div className='flex text-right'>
                            <div className='text-5xl'>15</div>
                            <div >
                                <div>Minggu</div>
                                <div>Mei 2022</div>
                            </div>
                        </div>
                        <div className='font-kalam font-bold'>Pukul: 10:00 Am - Selesai</div>
                    </div>

                </div>
            </div >
        </>
    )
}

export default TemaWaktu