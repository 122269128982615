import React from 'react'
import BismillahImage from '../images/bismillah.webp'
import Floral from '../images/floral-2.webp'

function TemaMainWedding({ children, className }) {
    return (
        <>
            <div className='flex flex-col justify-center items-center -mt-32 text-yellow-900'>
                <img src={BismillahImage} width="200" />
                <div className='text-center px-5 font-kalam'>
                    <div className='mt-5 font-bold'>  Assalamualaikum Warahmatullahi Wabarakatuh</div>
                    <div className='mt-5 font-bold text-lg'><i>“Dan di antara tanda-tanda kekuasaan-Nya ialah Dia menciptakan untukmu istri-istri dari jenismu sendiri, supaya kamu cenderung dan merasa tentram kepadanya, dan dijadikan-Nya di antaramu rasa kasih dan sayang. Sesungguhnya pada yang demikian itu benar-benar terdapat tanda-tanda bagi kaum yang berpikir.”</i>
                    </div>
                    <div className='py-5'>(QS Ar-Rum : 21)</div>
                    <div className='mt-5 text-lg'>
                        Maha suci Allah menciptakan makhluk-Nya berpasang-pasangan ya Allah perkenankan lah kami merangkaikan kasih sayang yang Kau ciptakan di antara putra putri kami:
                    </div>
                </div>
                <div className='pt-10 w-full '>

                    <div className='text-center'>

                        <div className='text-2xl font-courgette'>Sustika Hamid, ST</div>
                        <div className='text-base font-kalam'>Putri kedua dari Bapak Kasmit & Ibu Suarni</div>
                    </div>


                </div>

                <div className='py-5 w-full'>
                    <div className='text-left pl-32 font-great-vibes text-5xl'>
                        ( Tika )
                    </div>
                    <div className='pt-2 font-great-vibes text-5xl text-center'>&</div>
                    <div className='text-right pr-32 font-great-vibes text-5xl'>
                        ( Rexi)
                    </div>
                </div>

                <div className='pt-2 w-full '>


                    <div className='text-center'>

                        <div className='text-2xl font-courgette'>Rexi Abdi Syofyan, A.Md</div>
                        <div className='text-base font-kalam'>Putra kedua dari Bapak Syofyan (Alm) & Ibu Nova Evianti, S.Pd</div>
                    </div>

                </div>

                <img src={Floral} />

            </div>
        </>
    )
}

export default TemaMainWedding;